import "./App.css";
import UnderConstruction from "./Pages/UnderConstruction";
function App() {
  return (
    <>
      <UnderConstruction />
    </>
  );
}

export default App;
