import React from "react";
import "./styles.css";
import UnderConstructionImage from "../Images/agrohaat_underConstruction.png";

const UnderConstruction = (props) => {
  return (
    <div className="alignCenter">
      <img src={UnderConstructionImage} className="imageContainer" alt="logo" />
    </div>
  );
};

export default UnderConstruction;
